import connection from '@utils/connection'
import logger from '@utils/logger'

import Azure from '@utils/azure/Azure'

const STATUS_RUNNING = 'running'
const STATUS_COMPLETED = 'completed'

const DEFAULT_IDLE_TIMEOUT = 30000 // Timeout d'inactivté
const DEFAULT_ELAPSE_TIMEOUT = 10 * DEFAULT_IDLE_TIMEOUT // Timeout global
let REQID = 0

class WS {
    url: string
    method: string
    body?: { [key: string]: any }
    useCredentials: boolean // Attention: oui par déaut

    constructor(url: string) {
        // l'url a appeler
        this.url = url
        // get ou post
        this.method = ''
        // Si post, contenu à poster
        this.body = undefined
        // Si on doit utiliser le token
        this.useCredentials = true
    }
    get() {
        this.method = 'GET'
        this.body = undefined
        return this
    }
    post(body?: { [key: string]: any }) {
        this.method = 'POST'
        this.body = body
        return this
    }
    put(body: { [key: string]: any }) {
        this.method = 'PUT'
        this.body = body
        return this
    }
    patch(body?: { [key: string]: any }) {
        this.method = 'PATCH'
        this.body = body
        return this
    }
    delete() {
        this.method = 'DELETE'
        return this
    }
    noCredentials() {
        // Desactive l'utilisation du token pour cette requete
        this.useCredentials = false
        return this
    }

    execute = <T = void>(): Promise<T> => {
        if (!connection.isConnected()) {
            return Promise.reject('No network')
        }

        const TokenPromise: Promise<Credentials | void> = this.useCredentials
            ? Azure.assureToken('server')
            : Promise.resolve()

        return TokenPromise.catch(err => {
            // erreur msal - disconnect user
            logger.error(err)
            Azure.logout()

            throw err
        })
            .then((credentials: Credentials | void) => {
                const reqId = REQID++
                const body = this.body && JSON.stringify(this.body)

                try {
                    const progress = {
                        lastEvent: Date.now(),
                        status: STATUS_RUNNING,
                    }

                    let xhr: XMLHttpRequest

                    const promises = []
                    // La requete
                    promises[promises.length] = new Promise((accept, reject) => {
                        xhr = new XMLHttpRequest()
                        xhr.onprogress = () => {
                            progress.lastEvent = Date.now()
                        }
                        xhr.onerror = e => {
                            progress.status = STATUS_COMPLETED
                            reject(e)
                        }
                        xhr.onload = (e: any) => {
                            progress.status = STATUS_COMPLETED
                            accept({
                                status: xhr.status,
                                json: () => (e && e.target ? JSON.parse(e.target.responseText) : undefined),
                                text: () => (e && e.target ? e.target.responseText : undefined),
                                getResponseHeader: (h: any) => xhr.getResponseHeader(h),
                            })
                        }
                        xhr.onloadend = () => {
                            if (progress.status === STATUS_RUNNING) {
                                progress.status = STATUS_COMPLETED
                                reject('Unknown error. Timeout ?')
                            }
                        }
                        xhr.open(this.method, this.url, true)
                        xhr.timeout = DEFAULT_ELAPSE_TIMEOUT // Sur IE11, le timeout doit etre affecté APRES open()

                        if (this.body) {
                            xhr.setRequestHeader('Content-Type', 'application/json')
                        }
                        if (this.useCredentials && credentials && credentials.accessToken) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + credentials.accessToken)
                        }

                        xhr.send(body)
                    })

                    // Le timeout
                    promises[promises.length] = new Promise((resolve, reject) => {
                        const triggerTimeout = () =>
                            setTimeout(() => {
                                // On verifie regulierement que c'est terminé ou pas.
                                if (progress.status === STATUS_RUNNING) {
                                    if (
                                        Date.now() > progress.lastEvent &&
                                        Date.now() - progress.lastEvent > DEFAULT_IDLE_TIMEOUT
                                    ) {
                                        logger.debug(
                                            'req[' +
                                                reqId +
                                                '] Timeout (' +
                                                DEFAULT_IDLE_TIMEOUT +
                                                ') for ' +
                                                this.url +
                                                '. Cancelling.',
                                        )
                                        progress.status = STATUS_COMPLETED
                                        if (xhr) {
                                            try {
                                                xhr.abort()
                                            } catch (e) {
                                                logger.error('ERROR WHILE ABORT', e)
                                            }
                                        }
                                        reject('errors time out')
                                        return
                                    } else {
                                        // Wait more
                                        triggerTimeout()
                                    }
                                }
                            }, 10000)
                        triggerTimeout()
                    })

                    const startTimestamp = Date.now()

                    return Promise.race(promises)
                        .then((response: any) => {
                            progress.status = STATUS_COMPLETED
                            logger.debug(
                                'req[' +
                                    reqId +
                                    '] got answer from server in ' +
                                    (Date.now() - startTimestamp) +
                                    'ms for url=' +
                                    this.url +
                                    '. status=' +
                                    response.status,
                            )
                            if (response.status === 200) {
                                return response.json()
                            } else if (response.status > 200 && response.status <= 299) {
                                return { status: response.status, data: {} }
                            } else {
                                throw new Error(response.json().code + ' ' + response.json().message)
                            }
                        })
                        .then(json => {
                            if (!json) {
                                throw new Error('No data')
                            }
                            return json
                        })
                        .catch(error => {
                            progress.status = STATUS_COMPLETED
                            logger.error('req[' + reqId + '] Error while accessing ' + this.url + ' : ', error)
                            throw error
                        })
                } catch (error) {
                    return Promise.reject(error)
                }
            })
            .catch(err => {
                // erreur adal
                logger.error(err)
                throw err
            })
    }
}

export default WS
